<template>
  <div>
    <b-row>
      <b-col>
        <div id="component-breadcrumbs">
          <b-breadcrumb
            class="breadcrumb-slash"
            :items="breadcrumbItems"
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h2 v-if="ImpactRatingID">
          Edit Impact Rating
        </h2>
        <h2 v-else>
          Add Impact Rating
        </h2>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col md="10">
        <div class="white-bg-container">
          <b-row>
            <b-col md="3">
              <span>Impact Rate</span>
            </b-col>
            <b-col>
              <b-form-input
                id="impactRate"
                v-model="ImpactRate"
                name="vehicleName"
                :readonly="true"
              />
            </b-col>
          </b-row>
          <b-row class="pt-1 pb-1">
            <b-col md="3">
              <span>Impact Reason</span>
            </b-col>
            <b-col>
              <div
                class="collapse-textarea"
                :class="{expanded: !isExpanded}"
              >
                <textarea
                  v-model="ImpactReason"
                  class="expandable-textarea"
                  :class="[isExpanded ? 'not-expanded' : 'expanded']"
                  contentEditable
                />
                <div @click="isExpanded = !isExpanded">
                  <span
                    class="expand-arrow mt-n1"
                    :class="[isExpanded ? 'not-expanded' : 'expanded']"
                  >&#187;</span>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-row class="pt-2">
      <b-col>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          class="mr-2"
          type="reset"
          variant="danger"
          @click="resetForm()"
        >
          Cancel
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="submit"
          variant="primary"
          @click="saveForm()"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BBreadcrumb, BButton, BCol, BFormInput, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import APIService from "@core/utils/APIService";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BBreadcrumb,
    BFormInput,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      ImpactRate: "",
      ImpactReason: "",

      ImpactRatingID: "",
      impactRatings: [],

      isExpanded: false,
    }
  },
  computed: {
    breadcrumbItems: function() {
      return [
        {
          text: 'Admin',
        },
        {
          text: 'Utility',
          href: '/admin/utility'
        },
        {
          text: "Impact Rating Editor",
          href: "/admin/utility/impact-rating-editor"
        },
        {
          text: this.ImpactRatingID ? "Edit Impact Rating" : "Add Impact Rating",
        },
      ]
    },
  },
  created() {
    this.ImpactRatingID = this.$route.params.ImpactRatingID;
    if (this.ImpactRatingID) {
      apiService
          .get("impact-rating/" + this.ImpactRatingID + "/detail")
          .then(res => {
            this.ImpactRate = res.data.ImpactRate;
            this.ImpactReason = res.data.ImpactReason;
          })
    } else {
      apiService
          .get("impact-rating/list")
          .then(res => {
            this.impactRatings = res.data || [];
            this.ImpactRate = this.impactRatings.length + 1;
          })
    }
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "InfoIcon",
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          }
      );
    },
    resetForm() {
      this.$router.push('/admin/utility/impact-rating-editor')
    },
    saveForm() {
      const postData = {
        "ImpactReason": this.ImpactReason,
        "ImpactRate": this.ImpactRate
      }
      const endpoint = this.ImpactRatingID ? "impact-rating/" + this.ImpactRatingID + "/edit" : "impact-rating/create"
      apiService
          .post(endpoint, postData)
          .then((res) => {
            if (res) {
              this.showToast("success", "top-center", 4000, "Impact Rating has been created or updated");
              this.$router.push('/admin/utility/impact-rating-editor')
            }
          })
    },
  }
}
</script>

<style scoped>

</style>